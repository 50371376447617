/* eslint-disable jsx-a11y/alt-text */
import '../css/App.css';
import QR_BG from '../../images/login-bg.svg';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { toast } from 'react-toastify';

import { useState } from 'react';
import { PulseLoader } from 'react-spinners';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthService } from '../../services/auth';
import { FormControl } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';

function ConfirmPassword() {
  const nav = useNavigate();
  const { state } = useLocation();
  // const username = state?.username;
  const [username] = useState(state?.username);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  let [loading, setLoading] = useState(false);

  const { handleSubmit, control, formState: { errors }, getValues } = useForm({
    defaultValues: {
      password: '',
      confirmpassword: ''
    },
  })

  async function confirmPassword() {

    try {
      if (loading) {
        return;
      }
      setLoading((s) => !s);
      AuthService('/users/password-reset/set-password/', { email: username, password1: getValues('password'), password2: getValues('confirmpassword') }, (response) => {
        if (response && response.success) {
          nav('/');
          toast.success('Password Updated');
        } else {
          if (response && response.error && response.error.response && response.error.response.data && response.error.response.data.non_field_errors && response.error.response.data.non_field_errors.length > 0) {
            toast.error(`Reset failed. ${response.error.response.data.non_field_errors[0]}`)
          } else {
            toast.error('Reset failed!');
          }
        }
      })
    } catch (error) {
      if (error.message === 'Attempt limit exceeded, please try after some time.') {
        toast.error('Attempt limit exceeded, please try after some time.');
      } else {
        toast.error(error.message);
      }
    }
    setLoading((s) => !s);

  }

  return (
    <>
      <div className='LogInContainer'>
        <div className='LogIn_ImageDiv'>
          <img className='LogIn_Image' src={QR_BG} />
        </div>
        <div className='LogIn_RightDiv'>
          <form className='LogIn_FormDiv' onSubmit={handleSubmit(confirmPassword)}>
            <div className='LogIn_HeadingDiv'>
              <h2>New Password</h2>
              <p>To continue, please enter the new password and confirm password</p>
            </div>
            <div className='LogIn_InputDiv'>
              <FormControl fullWidth className='mb-1'>
                <label for="Password" style={{ display: "inline-flex" }}>
                  Password <span className='text-danger'>*</span>
                </label>

                <Controller
                  name='password'
                  control={control}

                  rules={{
                    required: 'Password is required',
                    minLength: {
                      value: 8,
                      message: 'Password must be at least 8 characters',
                    },
                  }}
                  render={({ field }) => (
                    <div className='LogIn_InputPasswordDiv'>
                      <input
                        {...field}
                        className='LogIn_InputPassword'
                        type={!showPassword ? 'password' : 'text'}
                        placeholder='Enter Password'
                        id="Password"
                      />
                      <div>
                        {showPassword ? (
                          <VisibilityOutlinedIcon
                            style={{ color: '#818181', cursor: 'pointer' }}
                            onClick={() => setShowPassword(!showPassword)}
                          />
                        ) : (
                          <VisibilityOffOutlinedIcon
                            style={{ color: '#818181', cursor: 'pointer' }}
                            onClick={() => setShowPassword(!showPassword)}
                          />
                        )}
                      </div>
                    </div>
                  )}
                />
                {errors.password && (<p className='error-validation'>{errors.password.message}</p>)}

              </FormControl>

              <FormControl fullWidth className='mb-1'>
                <label for="Password" style={{ display: "inline-flex" }}>
                  Confirm Password <span className='text-danger'>*</span>
                </label>

                <Controller
                  name='confirmpassword'
                  control={control}

                  rules={{
                    required: 'Confirm Password is required',
                    validate: (value) => value === getValues('password') || 'Passwords does not match',
                  }}
                  render={({ field }) => (
                    <div className='LogIn_InputPasswordDiv'>
                      <input
                        {...field}
                        className='LogIn_InputPassword'
                        type={!showConfirmPassword ? 'password' : 'text'}
                        placeholder='Enter Confirm Password'
                        id="confirmpassword"
                      />
                      <div>
                        {showConfirmPassword ? (
                          <VisibilityOutlinedIcon
                            style={{ color: '#818181', cursor: 'pointer' }}
                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                          />
                        ) : (
                          <VisibilityOffOutlinedIcon
                            style={{ color: '#818181', cursor: 'pointer' }}
                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                          />
                        )}
                      </div>
                    </div>
                  )}
                />
                {errors.confirmpassword && (<p className='error-validation'>{errors.confirmpassword.message}</p>)}
              </FormControl>
            </div>

            <div className='LogIn_BtnDiv'>
              <button className='LogIn_Btn'>
                {!loading ? <div> Submit</div> : <PulseLoader size={15} color='#ffffff' />}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default ConfirmPassword;
