import * as React from 'react';
import Box from '@mui/material/Box';
import { Modal, Button, FormControl, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify';
import { PulseLoader } from 'react-spinners';
import { AddMyItem } from '../../services/user';
import { Add } from '@mui/icons-material'
import { useForm, Controller } from 'react-hook-form';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -28%)',
    width: 480,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px',
    overflow: 'auto',

};


export default function BasicModal({ open, setOpen, itemId, getList, itemTypes }) {

    const [loading, setLoading] = React.useState(false);
    const fileInputRefs = [React.useRef(null), React.useRef(null), React.useRef(null)];

    const { handleSubmit, control, formState: { errors }, getValues, setValue } = useForm({
        defaultValues: {
            name: '',
            manufacturer: '',
            serial: '',
            estimated: '',
            type: '',
            description: '',
            photos: ['', '', ''],
        },
    })


    const handleImageChange = (e, index) => {
        e.stopPropagation()
        const file = e.target.files[0]
        if (file) {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onloadend = () => {
                const currentPhotos = getValues('photos')
                const updatedPhotos = [...currentPhotos]
                updatedPhotos[index] = reader.result
                setValue('photos', updatedPhotos, { shouldValidate: true })
            }
        }
    }

    const handleClick = (index) => {
        if (fileInputRefs[index].current) {
            fileInputRefs[index].current.click();
        }
    };

    const handleClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return;
        }
        setOpen(false);
    };

    const closeModal = () => {
        setOpen(false);
    };


    const handleRemoveImage = (index) => {
        const currentPhotos = getValues('photos')
        const updatedPhotos = [...currentPhotos]
        updatedPhotos[index] = ''
        setValue('photos', updatedPhotos, { shouldValidate: true })
    }

    async function submitItem() {

        if (loading) {
            return;
        }
        const estimatedValue = parseFloat(getValues('estimated'),).toFixed(2);

        const params = {
            name: getValues('name'),
            manufacturer: getValues('manufacturer'),
            serial_no: getValues('serial'),
            item_type: getValues('type'),
            estimated_value: estimatedValue,
            description: getValues('description')
        }

        if (itemId) {
            params.item_id = itemId
        }

        const photos = getValues('photos')
        const images = []
        if (photos && photos.length > 0) {
            for (let i = 0; i < photos.length; i++) {
                if (photos[i] && photos[i] !== "") {
                    images.push({ photo: photos[i], is_cover: (i === 0) ? true : false })
                }
            }
        }

        params.photos = images

        setLoading(true);
        try {
            AddMyItem(params, (response) => {
                setLoading(false);
                if (response && response.success) {
                    setOpen(false)
                    toast.success('Item added successfully!');
                    getList()
                } else {
                    if (response && response.error && response.error.data && response.error.data.detail) {
                        toast.error(`${response && response.error && response.error.data.detail}`)
                    } else {
                        toast.error(`Failed to add item!`)
                    }
                }
            });
        } catch (error) {
            setLoading(false);
        }

    }

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
                disableEscapeKeyDown
                style={{ overflow: 'auto' }}
            >
                <Box sx={style}>
                    <div style={{ position: 'relative', width: '100%' }}>
                        <div
                            style={{
                                position: 'absolute',
                                right: '-5%',
                                marginTop: '-5%',
                                border: '2px solid #000',
                                borderRadius: '50%',
                                padding: '3px',
                                backgroundColor: '#fff',
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                            onClick={closeModal}
                        >
                            <CloseIcon />
                        </div>

                    </div>
                    <div
                        className='text-center'
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>

                        <Typography sx={{ marginTop: '5%' }} id='modal-modal-title' style={{ fontWeight: 'bold' }} component='h6' variant='h6'>
                            Add Item
                        </Typography>

                        <form onSubmit={handleSubmit(submitItem)}>

                            <FormControl fullWidth className=''>
                                <label for="name" style={{ display: "inline-flex" }}>
                                    Item Name <span className='text-danger'>*</span>
                                </label>

                                <Controller
                                    name="name"
                                    control={control}
                                    rules={{ required: 'Item Name is required' }}
                                    render={({ field }) => (
                                        <input
                                            {...field}
                                            className='LogIn_InputEmail mb-2'
                                            type="text"
                                            id="name"
                                            placeholder="Enter Item Name"
                                            style={{
                                                width: '100%',
                                                height: '50px',
                                                padding: '10px',
                                            }}
                                        />
                                    )}
                                />
                                {errors.name && <p className='error-validation ' style={{ textAlign: 'left' }}>{errors.name.message}</p>}
                            </FormControl>

                            <FormControl fullWidth className=''>
                                <label for="manufacturer" style={{ display: "inline-flex" }}>
                                    Item Manufacturer <span className='text-danger'>*</span>
                                </label>
                                <Controller
                                    name="manufacturer"
                                    control={control}
                                    rules={{ required: 'Item Manufacturer is required' }}
                                    render={({ field }) => (
                                        <input
                                            {...field}
                                            className='LogIn_InputEmail mb-2'
                                            type="text"
                                            id="manufacturer"
                                            placeholder="Enter Item Manufacturer"
                                            style={{
                                                width: '100%',
                                                height: '50px',
                                                padding: '10px',
                                            }}
                                        />
                                    )}
                                />
                                {errors.manufacturer && <p className='error-validation' style={{ textAlign: 'left' }}>{errors.manufacturer.message}</p>}

                            </FormControl>

                            <FormControl fullWidth className=''>
                                <label for="serial" style={{ display: "inline-flex" }}>
                                    Item Serial Number <span className='text-danger'>*</span>
                                </label>

                                <Controller
                                    name="serial"
                                    control={control}
                                    rules={{ required: 'Serial Number is required' }}
                                    render={({ field }) => (
                                        <input
                                            {...field}
                                            className='LogIn_InputEmail mb-2'
                                            type="text"
                                            id="serial"
                                            placeholder="Enter Serial Number"
                                            style={{
                                                width: '100%',
                                                height: '50px',
                                                padding: '10px',
                                            }}
                                        />
                                    )}
                                />
                                {errors.serial && <p className='error-validation' style={{ textAlign: 'left' }}>{errors.serial.message}</p>}
                            </FormControl>


                            <FormControl fullWidth className=''>
                                <label htmlFor="estimated" style={{ display: "inline-flex" }}>
                                    Estimated Value <span className='text-danger'>*</span>
                                </label>

                                <Controller
                                    name="estimated"
                                    control={control}
                                    rules={{
                                        required: 'Estimated Value is required',

                                    }}
                                    render={({ field }) => (
                                        <input
                                            {...field}
                                            className='LogIn_InputEmail mb-2'
                                            type="text"
                                            id="estimated"
                                            placeholder="Enter Estimated Value"
                                            onInput={(e) => {
                                                e.target.value = e.target.value.replace(/[^0-9.]/g, '')
                                                    .replace(/(\..*?)\..*/g, '$1')
                                                    .replace(/^(\d+)(\.\d{0,2})?.*/, '$1$2');
                                            }}
                                            style={{
                                                width: '100%',
                                                height: '50px',
                                                padding: '10px',
                                            }}
                                        />
                                    )}
                                />
                                {errors.estimated && (
                                    <p className='error-validation' style={{ textAlign: 'left' }}>
                                        {errors.estimated.message}
                                    </p>
                                )}
                            </FormControl>


                            <div className='select-card'>
                                <FormControl fullWidth className=''>
                                    <label for="type" style={{ display: "inline-flex" }}>
                                        Item Type
                                    </label>

                                    <Controller
                                        name="type"
                                        control={control}

                                        render={({ field }) => (
                                            <select
                                                {...field}
                                                className='LogIn_InputEmail mb-2'
                                                style={{
                                                    width: '100%',
                                                    height: '50px',
                                                    padding: '10px',
                                                }}
                                            >
                                                <option value="">Select Type</option>
                                                {itemTypes?.map((tp) => (
                                                    <option key={tp.id} value={tp.id}>
                                                        {tp.name}
                                                    </option>
                                                ))}
                                            </select>
                                        )}
                                    />

                                </FormControl>

                                <FormControl fullWidth className=''>
                                    <label for="description" style={{ display: "inline-flex" }}>
                                        Description <span className='text-danger'>*</span>
                                    </label>

                                    <Controller
                                        name="description"
                                        control={control}
                                        rules={{ required: 'Description is required' }}
                                        render={({ field }) => (
                                            <textarea
                                                {...field}
                                                className='LogIn_InputEmail'
                                                id="description"
                                                rows="3"
                                                cols="29"
                                                placeholder="Enter Description"
                                                style={{
                                                    width: '100%',
                                                    padding: '3% 3%',
                                                }}
                                            />
                                        )}
                                    />
                                    {errors.description && <p className='error-validation' style={{ textAlign: 'left' }}>{errors.description.message}</p>}
                                </FormControl>
                            </div>


                            <FormControl fullWidth className=''>
                                <label htmlFor="image-upload" className='mb-2' style={{ display: "inline-flex" }}>
                                    Upload Item Images <span className='text-danger'>*</span>
                                </label>
                                <Controller
                                    name="photos"
                                    control={control}
                                    rules={{
                                        validate: (photos) => photos.filter(photo => photo !== '').length === 3 || 'You need to upload 3 images.'
                                    }}
                                    render={({ field }) => (
                                        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                                            {field.value.map((photo, index) => (
                                                <div
                                                    key={index}
                                                    style={{
                                                        width: 70,
                                                        height: 70,
                                                        borderRadius: '10%',
                                                        backgroundColor: '#f5f5f5',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        cursor: 'pointer',
                                                        position: 'relative',
                                                        marginRight: '10px',
                                                        marginTop: '10px',
                                                        border: '1px solid rgb(223, 223, 223)'
                                                    }}
                                                    onClick={() => handleClick(index)}
                                                >
                                                    {photo ? (
                                                        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                                                            <img src={photo} alt={`Selected ${index}`} style={{ width: '100%', height: '100%', borderRadius: '8px' }} />
                                                            <div
                                                                style={{
                                                                    position: 'absolute',
                                                                    top: '-8px',
                                                                    right: '-8px',
                                                                    border: '2px solid #ff0000',
                                                                    borderRadius: '50%',
                                                                    padding: '3px',
                                                                    backgroundColor: '#ff0000',
                                                                    cursor: 'pointer',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                }}
                                                                onClick={(e) => {
                                                                    e.stopPropagation()
                                                                    handleRemoveImage(index)
                                                                }}
                                                            >
                                                                <CloseIcon style={{ width: '13px', height: '13px', color: 'white' }} />
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <Add style={{ width: '50%', height: '50%' }} />
                                                    )}
                                                    <input
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={(e) => handleImageChange(e, index)}
                                                        style={{ display: 'none' }}
                                                        ref={fileInputRefs[index]}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                />
                                {errors.photos && <p className='error-validation mt-3' style={{ textAlign: 'left' }}>{errors.photos.message}</p>}
                            </FormControl>


                            <Button
                                type="submit"
                                variant="outlined"
                                sx={{
                                    mt: 3,
                                    display: 'block',
                                    width: '100%',
                                    height: '50px',
                                    mx: 'auto',
                                    borderColor: '#0a3f74',
                                    borderRadius: '30px',
                                    backgroundColor: '#0a3f74',
                                    color: '#fff',
                                    '&:hover': {
                                        borderColor: '#0a3f74',
                                        color: '#fff',
                                        backgroundColor: '#0a3f74',
                                    },
                                }}

                            >
                                {!loading ? (
                                    <div>Submit</div>
                                ) : (
                                    <PulseLoader size={15} color='#ffffff' />
                                )}
                            </Button>
                        </form>
                    </div>
                </Box>
            </Modal>
        </div >
    );
}