/* eslint-disable jsx-a11y/alt-text */
import '../css/App.css';
import QR_BG from '../../images/login-bg.svg';
import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { PulseLoader } from 'react-spinners';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ReactCodeInput from 'react-code-input';
// import { reactCodeInput } from 'CodeInputField.scss';
import { AuthResendActivation, AuthConfirmActivation } from '../../services/auth';

function ReactivationCode() {

  const { state } = useLocation();
  const [username] = useState(state?.username);
  const nav = useNavigate();
  // const [showPassword, setShowPassword] = useState(false);

  let [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState({
    code: '',
    password: '',
    confirmpassword: '',
  });

  const [timer, setTimer] = useState(60);
  const [isTimerActive, setIsTimerActive] = useState(true);

  useEffect(() => {
    let timerInterval;
    if (isTimerActive && timer > 0) {
      timerInterval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setIsTimerActive(false);
    }

    return () => clearInterval(timerInterval);
  }, [isTimerActive, timer]);

  const formatTimer = () => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  async function resendConfirmationCode() {

    try {
      // await Auth.resendSignUp(username);
      AuthResendActivation({ email: username }, (response) => {

        if (response && response.success) {
          toast.success('Code resent successfully');
          setTimer(60);
          setIsTimerActive(true);
        } else {
          toast.error("Resend failed. Please try again later!")
        }
      });
    } catch (err) {
      toast.error(err);
      console.log('error resending code: ', err);
    }

  }

  useEffect(() => {
    if (!username) nav('/');
    // if()
  }, []);

  async function confirmActivation() {

    if (formState.code === '') {
      toast.error('Verification code cannot be empty');
      return false
    }

    setLoading((s) => !s);
    try {
      AuthConfirmActivation({ email: username, code: formState.code }, (response) => {
        setLoading((s) => !s);
        if (response && response.success) {
          nav('/')
          toast.success('Account activated successfully');

        } else {
          toast.error("Invalid code. Please try again later!")
        }
      });
    } catch (error) {
      if (error.message === 'Confirmation code cannot be empty') {
        toast.error('Confirmation code cannot be empty.');
      } else {
        toast.error('Invalid verification code provided, please try again.');
      }
      console.log('error confirming sign up', error);
      setLoading((s) => !s);
    }
  }

  return (
    <>
      <div className='LogInContainer'>
        <div className='LogIn_ImageDiv'>
          <img className='LogIn_Image' src={QR_BG} />
        </div>
        <div className='LogIn_RightDiv'>
          <form className='LogIn_FormDiv' onSubmit={(e) => e.preventDefault()}>
            <div className='LogIn_HeadingDiv'>
              <h2>Verification Code</h2>
              <p>To continue please enter the 6 digit token sent to your email/phone number</p>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <ReactCodeInput
                className='OtpInputBox'
                fields={6}
                value={formState.code}
                onChange={(e) => {
                  setFormState((s) => ({ ...s, code: e }));
                }}
              />
            </div>

            <div className='LogIn_BtnDiv'>
              <button onClick={confirmActivation} className='LogIn_Btn'>
                {!loading ? <div> Submit</div> : <PulseLoader size={15} color='#ffffff' />}
              </button>
            </div>
            <div className='LogIn_SignUpDiv' >
              <p>
                Didn't receive token?{' '}
                <Link
                  style={{
                    color: isTimerActive ? '#ccc' : '#000',
                    fontWeight: '700',
                    pointerEvents: isTimerActive ? 'none' : 'auto',
                    textDecoration: isTimerActive ? 'none' : 'underline',
                  }}
                  onClick={resendConfirmationCode}
                >
                  Request again
                </Link>
                {isTimerActive && <span style={{ marginLeft: '10px' }}>({formatTimer()})</span>}
              </p>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default ReactivationCode;
