import axios from 'axios';

const Axios = axios.create({
  // baseURL: 'https://develop.qrtag.it/api/v1',
  baseURL: 'https://api.qrtag.it/api/v1',
  headers: {
    accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

Axios.interceptors.request.use(function (config) {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem('accessToken')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }

    if (config.data instanceof FormData) {
      // For form-data, axios will handle the boundary automatically when FormData is used
      config.headers['Content-Type'] = '';
    }

    resolve(config);
  });
});

// Add a response interceptor
Axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.setItem('isLoggedIn', 'false');
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = '/'
    }
    return Promise.reject(error);
  }
);

export default Axios;
