/* eslint-disable jsx-a11y/alt-text */
import '../css/App.css';
import QR_BG from '../../images/login-bg.svg';
import { useState } from 'react';
import { PulseLoader } from 'react-spinners';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AuthService } from '../../services/auth';
import { FormControl } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';

function DeleteAccount() {

  const nav = useNavigate();

  let [loading, setLoading] = useState(false);

  const { getValues, control, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      email: ''
    },
  });

  async function handleDelete() {

    setLoading(true);
    try {
      AuthService('/users/delete-user/send-code/', { email: getValues('email') }, (response) => {
        setLoading(false);
        if (response && response.success) {
          nav('/account/delete/code', { state: { username: getValues('email') } });
        } else {
          if (response && response.error && response.error.data && response.error.data.detail) {
            toast.error(`${response && response.error && response.error.data.detail}`)
          } else if (response && response.error && response.error.data && response.error.data.message) {
            toast.error(response.error.data.message)
          } else {
            toast.error('Delete account failed!');
          }
        }
      });
    } catch (error) {
      if (
        error.message ===
        'Cannot reset password for the user as there is no registered/verified email or phone_number'
      ) {
        toast.error('User not registered');
      } else toast.error(error.message);

      console.log('error signing up', error);
      setLoading(false);
    }

  }

  return (
    <>
      <div className='LogInContainer'>
        <div className='LogIn_ImageDiv'>
          <img className='LogIn_Image' src={QR_BG} />
        </div>
        <div className='LogIn_RightDiv'>
          <form className='LogIn_FormDiv' onSubmit={handleSubmit(handleDelete)}>
            <div className='LogIn_HeadingDiv'>
              <h2>Delete Account?</h2>
              <p>
                Please Enter the mail associated with your account and we will send you a link to
                delete the account.
              </p>
            </div>
            <div className='LogIn_InputDiv'>
              <FormControl fullWidth>
                <label for="Email" style={{ display: "inline-flex" }}>
                  Email <span className='text-danger'>*</span>
                </label>

                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: "Email is required",
                    pattern: {
                      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      message: "Invalid email address",
                    },
                  }}
                  render={({ field }) => (
                    <input
                      {...field}
                      className='LogIn_InputEmail'
                      type='email'
                      placeholder='Enter Email'
                    />
                  )}
                />
                {errors.email && (
                  <p className="error-validation">
                    {errors.email.message}
                  </p>
                )}
              </FormControl>
            </div>
            <div className='LogIn_BtnDiv mt-4' >
              <button className='LogIn_Btn'>
                {!loading ? <div>Submit</div> : <PulseLoader size={15} color='#ffffff' />}
              </button>
            </div>
            <div className='LogIn_SignUpDiv'>
              <p>
                Back to {' '}
                <Link to='/' style={{ color: '#0A3F74', fontWeight: '700' }}>
                  Sign In
                </Link>
              </p>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default DeleteAccount;
