/* eslint-disable jsx-a11y/alt-text */
import '../css/App.css';
import QR_BG from '../../images/login-bg.svg';
import FB_Logo from '../../images/facebook-logo.svg';
import Apple_Logo from '../../images/apple-logo.svg';
import Google_Logo from '../../images/google-logo.svg';
import HidePassword from '../../images/hide-password.svg';
import SignUp from './SignUp';
import { useState } from 'react';
import { PulseLoader } from 'react-spinners';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AuthService } from '../../services/auth';
import { FormControl } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';

function ForgetPassword() {
  const nav = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  let [loading, setLoading] = useState(false);

  const { getValues, control, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      email: ''
    },
  });

  async function forgetPassword() {

    if (loading) {
      return;
    }
    setLoading((s) => !s);
    try {
      AuthService('/users/password-reset/send-token/', { email: getValues('email') }, (response) => {
        if (response && response.success) {
          console.log('Verification code sent');
          nav('/verifyresetcode', { state: { username: getValues('email') } });
        } else {
          const emailError = response?.error?.response?.data?.email?.[0]
          if (emailError) {
            toast.error(emailError)
          } else {
            toast.error('Forgot password failed!');
          }
        }
      });
    } catch (error) {
      if (
        error.message ===
        'Cannot reset password for the user as there is no registered/verified email or phone_number'
      ) {
        toast.error('User not registered');
      } else toast.error(error.message);

      console.log('error signing up', error);
    }
    setLoading((s) => !s);

  }

  return (
    <>
      <div className='LogInContainer'>
        <div className='LogIn_ImageDiv'>
          <img className='LogIn_Image' src={QR_BG} />
        </div>
        <div className='LogIn_RightDiv'>
          <form className='LogIn_FormDiv' onSubmit={handleSubmit(forgetPassword)}>
            <div className='LogIn_HeadingDiv'>
              <h2>Forgot Password?</h2>
              <p>
                Please Enter the mail associated with your account and we will send you a link to
                reset your password
              </p>
            </div>

            <div className='LogIn_InputDiv'>
              <FormControl fullWidth>
                <label for="Email" style={{ display: "inline-flex" }}>
                  Email <span className='text-danger'>*</span>
                </label>
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: "Email is required",
                    pattern: {
                      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      message: "Invalid email address",
                    },
                  }}
                  render={({ field }) => (
                    <input
                      {...field}
                      className='LogIn_InputEmail'
                      type='email'
                      placeholder='Enter Email'
                    />
                  )}
                />
                {errors.email && (
                  <p className="error-validation">
                    {errors.email.message}
                  </p>
                )}
              </FormControl>
            </div>
            <div className='LogIn_BtnDiv mt-4' >
              <button className='LogIn_Btn'>
                {!loading ? <div>Forgot Password</div> : <PulseLoader size={15} color='#ffffff' />}
              </button>
            </div>

            <div className='LogIn_SignUpDiv'>
              <p>
                Back to {' '}
                <Link to='/' style={{ color: '#0A3F74', fontWeight: '700' }}>
                  Sign In
                </Link>
              </p>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default ForgetPassword;
