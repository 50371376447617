/* eslint-disable jsx-a11y/alt-text */
import '../css/App.css';
import QR_BG from '../../images/login-bg.svg';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { UpdateStore } from '../../StoreContext';
import SocialLogin from '../common/SocialLogin';

// import { toast } from "react-toastify";
import { toast } from 'react-toastify';
import { Auth } from 'aws-amplify';
import { useEffect, useState, useCallback } from 'react';
import { PulseLoader } from 'react-spinners';
import { Link, useNavigate } from 'react-router-dom';
import { AuthLogin, AuthResendSignup, AuthResendActivation } from '../../services/auth';
import { FormControl } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';

function Login() {
    const nav = useNavigate();
    const updateStore = UpdateStore();
    const [showPassword, setShowPassword] = useState(false);
    // const [token, setToken] = useState(null)

    let [loading, setLoading] = useState();
    // const toast = toast({ position: toast.POSITION.BOTTOM_RIGHT });

    const { getValues, control, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            email: '',
            password: '',
        },
    });

    const [isDeleted, setIsDeleted] = useState(false);
    const [accountDeletedMessage, setAccountDeletedMessage] = useState('');

    const handleReactivate = () => {
        resendActivationCode();
        nav('/reactivationcode', { state: { username: getValues('email') } });
    }

    const handleCancel = () => {
        setIsDeleted(false)
    }

    async function resendConfirmationCode() {
        try {
            AuthResendSignup({ email: getValues('email') }, (response) => {
                if (response && response.success) {
                    // toast.success('Code resent successfully');
                } else {
                    // toast.error("Resend failed. Please try again later!")
                }
            });
        } catch (err) {
            console.log('error resending code: ', err);
        }
    }

    async function resendActivationCode() {
        try {
            AuthResendActivation({ email: getValues('email') }, (response) => {
                if (response && response.success) {
                    // toast.success('Code resent successfully');
                } else {
                    // toast.error("Resend failed. Please try again later!")
                }
            });
        } catch (err) {
            console.log('error resending code: ', err);
        }
    }

    async function signIn() {
        // if (!Object.values(formState).every(Boolean)) {
        //   toast.error('Fields cannot be empty', { position: toast.POSITION.BOTTOM_RIGHT });
        //   return;
        // }

        if (loading) {
            return;
        }
        setLoading((s) => !s);
        try {
            // const user = await Auth.signIn(formState.email, formState.password);
            AuthLogin({ username: getValues('email'), password: getValues('password') }, (response) => {

                setLoading((s) => !s);
                if (response && response.success) {
                    if (response?.data && !response.data.user?.has_accepted_terms) {
                        toast.error(`Please agree to the Terms & Conditions!`)
                        nav('/acceptterms', { state: { username: getValues('email') } });
                    } else if (response.data && !response.data.user?.email_verified) {
                        toast.error(`Please verify your email account!`)
                        resendConfirmationCode()
                        nav('/verificationcode', { state: { username: getValues('email') } });
                    } else if (response.data && !response.data.user?.is_active) {
                        setIsDeleted(true)
                        setAccountDeletedMessage(response.data?.message || 'It appears that your account was previously deleted. To reactivate your account, please verify your email address. A verification OTP will be sent to your registered email. Would you like to proceed with reactivation.');

                    } else if (response.data) {
                        localStorage.setItem('user', JSON.stringify(response?.data.user));
                        updateStore({ user: response?.data.user, loggedIn: true, accessToken: response?.data.access });
                        localStorage.setItem('userData', JSON.stringify(response?.data.user));
                        localStorage.setItem('accessToken', response?.data.access);
                        localStorage.setItem('refreshToken', response?.data.refresh);

                        if (sessionStorage.getItem('uuidcode')) {
                            nav(`/uuid/${sessionStorage.getItem('uuidcode')}`)
                        }
                    }
                } else {
                    if (response && response.error && response.error.data && response.error.data.detail) {
                        toast.error(`${response && response.error && response.error.data.detail}`)
                    } else {
                        toast.error(`Invalid login credentials!`)
                    }
                }
            });
            // const uid = localStorage.getItem('uid');
            // if (uid) {
            //   nav('/chat');
            // } else {
            //   nav('/');
            // }
            // setLoading((s) => !s);
            // toast.success('Login Successfully');
        } catch (error) {
            setLoading((s) => !s);

            if (error.message === 'User is not confirmed.') {
                toast.error('User is not confirmed.');
            } else if (error.message === 'Incorrect username or password.') {
                toast.error('Incorrect username or password.');
            } else {
                toast.error(error.message);
            }
            console.log('error signing in', error.message);
        }

    }

    return (
        <>
            <div className='LogInContainer'>
                <div className='LogIn_ImageDiv'>
                    <img className='LogIn_Image' src={QR_BG} />
                </div>
                <div className='LogIn_RightDiv'>
                    {isDeleted ? (
                        <>
                            <form className='LogIn_FormDiv' >
                                <div className='LogIn_HeadingDiv'>
                                    <h3 style={{ textAlign: 'center', fontWeight: 600 }}>Account Deleted</h3>
                                    <p className='pt-3' style={{ textAlign: 'justify' }}>
                                        {accountDeletedMessage}
                                    </p>
                                </div>

                                <div className='LogIn_BtnDiv'>
                                    <button onClick={handleReactivate} className='LogIn_Btn'>
                                        {!loading ? <div> Reactivate</div> : <PulseLoader size={15} color='#ffffff' />}
                                    </button>
                                </div>
                                <div className='LogIn_SignUpDiv' >
                                    <p>
                                        <Link style={{ color: '#000', fontWeight: '700' }} onClick={handleCancel}>Cancel</Link>
                                    </p>
                                </div>
                            </form>
                        </>

                    ) : (

                        <>

                            <form className='LogIn_FormDiv' onSubmit={handleSubmit(signIn)}>
                                <div className='LogIn_HeadingDiv'>
                                    <h2>Hello,</h2>
                                    <p>let’s help find your lost item</p>
                                </div>
                                <div className='LogIn_InputDiv'>
                                    <h3 className='pb-4'>Login</h3>

                                    <FormControl fullWidth className='mb-1'>
                                        <label htmlFor="Email" style={{ display: "inline-flex" }}>
                                            Email <span className='text-danger'>*</span>
                                        </label>
                                        <Controller
                                            name="email"
                                            control={control}
                                            rules={{
                                                required: "Email is required",
                                                pattern: {
                                                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                                    message: "Invalid email address",
                                                },
                                            }}
                                            render={({ field }) => (
                                                <input
                                                    {...field}
                                                    className='LogIn_InputEmail'
                                                    placeholder='Enter Email'
                                                />
                                            )}
                                        />
                                        {errors.email && (
                                            <p className="error-validation">
                                                {errors.email.message}
                                            </p>
                                        )}
                                    </FormControl>

                                    <FormControl fullWidth className='mb-1'>
                                        <label htmlFor="Password" style={{ display: "inline-flex" }}>
                                            Password <span className='text-danger'>*</span>
                                        </label>
                                        <Controller
                                            name="password"
                                            control={control}
                                            rules={{ required: "Password is required" }}
                                            render={({ field }) => (
                                                <div className='LogIn_InputPasswordDiv'>
                                                    <input
                                                        {...field}
                                                        className='LogIn_InputPassword'
                                                        type={!showPassword ? 'password' : 'text'}
                                                        placeholder='Enter Password'
                                                    />
                                                    <div>
                                                        {showPassword ? (
                                                            <VisibilityOutlinedIcon
                                                                style={{ color: '#818181', cursor: 'pointer' }}
                                                                onClick={() => setShowPassword(!showPassword)}
                                                            />
                                                        ) : (
                                                            <VisibilityOffOutlinedIcon
                                                                style={{ color: '#818181', cursor: 'pointer' }}
                                                                onClick={() => setShowPassword(!showPassword)}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        />
                                        {errors.password && (
                                            <p className="error-validation">
                                                {errors.password.message}
                                            </p>
                                        )}
                                    </FormControl>

                                </div>
                                <div className='LogIn_ForgotPasswordDiv'>
                                    <Link to='forgetpassword' className='LogIn_ForgotPasswordLink'>
                                        Forgot Password?
                                    </Link>
                                </div>
                                <div className='LogIn_BtnDiv'>
                                    <button className='LogIn_Btn'>
                                        {!loading ? (
                                            <div> Sign In</div>
                                        ) : (
                                            <PulseLoader size={15} color='#ffffff' />
                                        )}
                                    </button>
                                </div>
                                <div className='LogIn_SignUpDiv'>
                                    <p>
                                        Don’t have an account?{' '}
                                        <Link
                                            to='/signup'
                                            style={{ color: '#0A3F74', fontWeight: '600' }}
                                        >
                                            Sign up
                                        </Link>
                                    </p>
                                </div>
                                <div className='LogIn_DividerDiv'>
                                    <div className='LogIn_Divider' />
                                    <p>OR</p>
                                    <div className='LogIn_Divider' />
                                </div>

                                <div className='LogIn_SignInOptions'>
                                    <p>Sign in with</p>
                                </div>
                                <SocialLogin />

                            </form>

                        </>
                    )}
                </div >
            </div >
        </>
    );
}

export default Login;
