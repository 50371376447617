/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useRef, useState } from 'react';
import QRLogo from '../../images/navbar-logo.svg';
import LogoText from '../../images/logotext.svg';
// import HomeLogo from '../../images/navbar-home-logo.svg';
import AboutIcon from '../../images/navbar-about-icon.svg';
import MessageIcon from '../../images/navbar-message-icon.svg';
import DownloadIcon from '../../images/navbar-download-icon.svg';
import Avatar from '../../images/avatar.png';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import QrCodeScannerOutlinedIcon from '@mui/icons-material/QrCodeScannerOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import PersonRemoveOutlinedIcon from '@mui/icons-material/PersonRemoveOutlined';
import CardMembershipOutlinedIcon from '@mui/icons-material/CardMembershipOutlined';
import CardGiftcardOutlinedIcon from '@mui/icons-material/CardGiftcardOutlined';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import LuggageIcon from '@mui/icons-material/Luggage';
import { Collapse, List, ListItemButton, ListItemText } from '@mui/material';
import { PulseLoader } from 'react-spinners';
import { Store } from '../../StoreContext';
import ConfirmationModel from '../common/ConfirmationModel';
import ConfirmationDeleteModel from '../common/ConfirmationDeleteModel';
import FeedbackModal from '../common/FeedbackModal';
import ApplyCouponModal from '../common/ApplyCouponModal'
import { determineDeviceType } from '../../utils/functions';
import mixpanel from 'mixpanel-browser';
import { CircleOutlined } from '@mui/icons-material';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AppGuide from './AppGuide';
import QuestionModal from './QuestionModal';
import moment from 'moment-timezone'

function Navbar() {

  const { user, loggedIn } = Store();
  const nav = useNavigate();
  const location = useLocation();
  const { state } = useLocation();
  const [open, setOpen] = React.useState(false);
  const [openMenu, setOpenMenu] = React.useState(false);
  const [loggedOutMenu, setLoggedOutMenu] = React.useState(false);
  const [cancel, setCancel] = React.useState(false);
  const [profile, setProfile] = React.useState(null);

  const [loading, setLoading] = React.useState(false);
  const [deleteAccount, setDeleteAccount] = React.useState(false);
  const [feedback, setFeedback] = React.useState(false);
  const [applycoupon, setApplyCoupon] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [isHidden, setIsHidden] = React.useState(false);
  const [startGuide, setStartGuide] = React.useState(state?.appGuide || false);
  const [questionModal, setQuestionModal] = useState(false)

  const [subMenuStates, setSubMenuStates] = useState({
    subscription: false,
    security: false,
    myaccount: false,
    chats: false
  })

  const toggleSubMenu = (menu) => {
    // setSubMenuStates(prev => ({ ...prev, [menu]: !prev[menu] }))
    setSubMenuStates({
      subscription: false,
      security: false,
      myaccount: false,
      chats: false,
      [menu]: true
    })
  }

  const getCookie = (name) => {
    let cookies = document.cookie.split(';');
    for (let cookie of cookies) {
      let [key, value] = cookie.trim().split('=');
      if (key === name) return value;
    }
    return null;
  }

  useEffect(() => {
    if (getCookie('modalShown')) {
      setIsHidden(true)
    } else {
      setIsHidden(false)
    }
  }, [])

  const handleClick = () => {
    setOpen(!open);
  };
  const handleMenuClick = () => {
    setOpenMenu(!openMenu);
  };
  const handleLoggedOutMenuClick = () => {
    setLoggedOutMenu(!loggedOutMenu);
  };

  const device_type = determineDeviceType()
  const menuRef = useRef(null);
  const burgerMenu = useRef(null);
  const loggedOut = useRef(null);
  const overlayRef = useRef(null);
  const floaterRef = useRef(null);
  const listRefs = useRef([]);

  const listRef = (el) => {
    if (el && !listRefs.current.includes(el)) {
      listRefs.current.push(el);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (overlayRef.current && overlayRef.current.contains(event.target)) {
        return false
      }
      if (floaterRef.current && floaterRef.current.contains(event.target)) {
        return false
      }
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setOpen(false);
      }
      if (burgerMenu.current && !burgerMenu.current.contains(event.target)) {
        setOpenMenu(false);
      }
      if (loggedOut.current && !loggedOut.current.contains(event.target)) {
        setLoggedOutMenu(false);
      }
      if (listRefs.current.some(ref => ref && ref.contains(event.target))) {
        setOpen(true);
      }
    };
    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [loggedOut]);

  const isExpiringTrail = (info) => {
    const expires_at = info.terms_accepted_on;

    const expirationDate = moment(expires_at);
    const alertDate = expirationDate.subtract(7, 'days');

    if (moment().isSame(alertDate, 'day')) {
      return true
    }
    return true
  }

  useEffect(() => {
    if (user) {
      setProfile(user)
      if (user.is_trial === true && user.is_survey_submitted === false && isExpiringTrail(user) === true) {
        // setQuestionModal(true)
      }
      if (user.is_flyer_activated === false) {
        setShow(true)
      }
      if (user.is_tutorial_complete === false) {
        setStartGuide(true)
      }
    }
  }, [user])

  const handleAppGuide = () => {
    if (location) {
      if (["/", "/mystuff"].includes(location.pathname) === false) {
        nav('/', { state: { appGuide: true } })
        return false
      }
    }
    setStartGuide(true);
  }

  return (
    <>
      <div className='Navbar_Container'>
        <div className='container' style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className='Navbar_LogoDiv' style={{ cursor: 'pointer' }} onClick={() => nav('/')}>
            <img className='Navbar_Logo' src={QRLogo} />
            <img className='Navbar_LogoText' src={LogoText} />
          </div>
          <div className='Navbar_MenuContainer'>
            <div className='Navbar_MenuDiv mx-5'>
              <Link to='/about' className='Navbar_MenuLinks'>
                <img src={AboutIcon} />
                <p>About Us</p>
              </Link>

              <Link to='/chat' className='Navbar_MenuLinks chats'>
                <img src={MessageIcon} />
                <p >Chats</p>
              </Link>

              {/* <Link to='/store' className='Navbar_MenuLinks'>
              <ShoppingCartOutlinedIcon sx={{ color: '#fff' }} />
              <p>Store</p>
            </Link> */}
              {loggedIn &&
                <div className='mystuff'>
                  <Link to='/mystuff' className='Navbar_MenuLinks '>
                    <LuggageIcon sx={{ color: '#fff' }} />
                    <p>My Stuff</p>
                  </Link>
                </div>

              }
              {/* <a onClick={() => {
                mixpanel.track('Download app', {
                  buttonName: "Download the app"
                });
              }}
                className='Navbar_MenuLinks' href={device_type === "Android" ? "https://play.google.com/store/apps/details?id=com.withered_feather_36062" : "https://apps.apple.com/us/app/qrtag-it/id6444082603"}>
                <img src={DownloadIcon} />
                <p>Download App</p>
              </a> */}
            </div>
            {!loggedIn ? (
              <div
                ref={loggedOut}
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'center',
                }}
              >
                <div className='LogIn_BtnDiv'>
                  <button
                    className='LogIn_BtnWeb'
                    onClick={() => (window.location = '/')}
                  >
                    <div> Sign In</div>
                  </button>
                  <div className='expandIconsMob' onClick={handleLoggedOutMenuClick}>
                    {/* {loggedOutMenu ? (
                    <CloseIcon sx={{ color: '#ffffff' }} />
                  ) : ( */}
                    <MenuIcon
                      sx={{ color: '#ffffff' }} />
                    {/* )} */}
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div ref={menuRef} className='profile_div nav-menus'>
                  <ListItemButton className='px-0' sx={{ width: '100%' }} >
                    <div className='profilePic_div'
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'end',
                        width: '100%',
                      }}
                    >
                      <div>
                        <img
                          className='profile_img'
                          src={profile?.looser?.profile_picture
                            ? profile?.looser?.profile_picture
                            : Avatar} />
                      </div>
                      <p
                        style={{
                          color: '#ffffff',
                          margin: '0 5%',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {profile?.name || 'User'}
                      </p>
                      <div onMouseDown={handleClick} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                        {open || openMenu || loggedOutMenu ? (
                          <ExpandLess sx={{ color: '#ffffff' }} />
                        ) : (
                          <ExpandMore sx={{ color: '#ffffff' }} />
                        )}
                      </div>
                    </div>
                  </ListItemButton>
                </div>
                <div ref={burgerMenu} className='menu_Icon mobi-nav-menus' >
                  <ListItemButton sx={{ width: '100%' }} onClick={handleMenuClick}>
                    <MenuIcon sx={{ color: '#ffffff' }} />
                  </ListItemButton>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <Collapse
        className='Navbar_CollapseDiv navbar-collapse'
        in={open || openMenu || loggedOutMenu}
        timeout='auto'
        unmountOnExit
      >
        <List disablePadding>
          {loggedIn && (
            <>
              {/* <ListItemButton> */}
              <ListItemText>
                <div className='login_name'>
                  <div className='Navbar_CollapseText'>
                    {profile?.name || 'Username'}
                  </div>
                  <div className='Navbar_CollapseText' style={{ color: '#707070' }}>
                    {profile?.email || 'user@qrtagit.com'}
                  </div>
                </div>
              </ListItemText>
              {/* </ListItemButton> */}

              <div className='Navbar_CollapseDivider' />

              <div>
                <ListItemButton className="myaccount" ref={listRef} onClick={() => toggleSubMenu('myaccount')} style={{ borderBottom: "1px solid lightgray", borderTop: "1px solid lightgray" }}>
                  <ListItemText className='m-0' >
                    <div className='Navbar_CollapseText'>
                      My Account
                    </div>
                  </ListItemText>
                  {subMenuStates.myaccount ? <ExpandMore ref={listRef} /> : <ChevronRightIcon ref={listRef} />}
                </ListItemButton>

                <Collapse in={subMenuStates.myaccount} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemButton className='edit-profile editprofile' sx={{ pl: 4 }} onClick={() => nav('/editprofile')} >
                      <AccountCircleOutlinedIcon className='mx-1' style={{ height: '18px', width: '18px', marginRight: '8px' }} />
                      <ListItemText primary="Edit Profile" primaryTypographyProps={{ style: { fontSize: '13px' } }} />
                    </ListItemButton>
                    <div className='Navbar_CollapseDivider' />
                    <ListItemButton className='edit-profile' sx={{ pl: 4 }} onClick={() => { handleAppGuide() }}>
                      <DescriptionOutlinedIcon className='mx-1' style={{ height: '18px', width: '18px', marginRight: '8px' }} />
                      <ListItemText primary="App Guide" primaryTypographyProps={{ style: { fontSize: '13px' } }} />
                    </ListItemButton>
                    <div className='Navbar_CollapseDivider' />
                    <ListItemButton sx={{ pl: 4 }} onClick={() => { setCancel(true) }} style={{ paddingTop: '5px', paddingBottom: '5px' }}>
                      <LogoutOutlinedIcon className='mx-1' style={{ height: '15px', width: '15px', color: '#FF0000', marginRight: '8px' }} />
                      <ListItemText primary="Sign Out" primaryTypographyProps={{ style: { fontSize: '13px', color: '#FF0000' } }} />
                      <div>
                        {loading && <PulseLoader size={10} color='#0A3F74' />}
                      </div>
                    </ListItemButton>
                    <div className='Navbar_CollapseDivider' />
                    <ListItemButton sx={{ pl: 4 }} onClick={() => setDeleteAccount(true)} style={{ color: '#FF0000' }}>
                      <PersonRemoveOutlinedIcon className='mx-1' style={{ height: '18px', width: '18px', marginRight: '8px' }} />
                      <ListItemText primary="Delete Account" primaryTypographyProps={{ style: { fontSize: '13px' } }} />
                    </ListItemButton>
                  </List>
                </Collapse>
              </div>

              <div>
                <ListItemButton className='mobi-chats' ref={listRef} onClick={() => nav('/chat')} style={{ borderBottom: "1px solid lightgray", borderTop: "1px solid lightgray" }}>
                  <ListItemText className='m-0' >
                    <div className='Navbar_CollapseText' >
                      Chat
                    </div>
                  </ListItemText>
                  {<ChevronRightIcon />}
                </ListItemButton>
              </div>

              <div>
                <ListItemButton ref={listRef} onClick={() => toggleSubMenu('subscription')} style={{ borderBottom: "1px solid lightgray" }}>
                  <ListItemText className='m-0' >
                    <div className='Navbar_CollapseText' >
                      Subscription
                    </div>
                  </ListItemText>
                  {subMenuStates.subscription ? <ExpandMore ref={listRef} /> : <ChevronRightIcon ref={listRef} />}
                </ListItemButton>

                <Collapse in={subMenuStates.subscription} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 4 }} onClick={() => nav('/subscription')}>
                      <CardMembershipOutlinedIcon style={{ height: '18px', width: '18px', marginRight: '8px' }} />
                      <ListItemText primary="Manage Subscription" primaryTypographyProps={{ style: { fontSize: '13px' } }} />
                    </ListItemButton>
                    <div className='Navbar_CollapseDivider' />
                    <ListItemButton sx={{ pl: 4 }} onClick={() => setApplyCoupon(true)}>
                      <CardGiftcardOutlinedIcon style={{ height: '18px', width: '18px', marginRight: '8px' }} />
                      <ListItemText primary="Apply Coupon" primaryTypographyProps={{ style: { fontSize: '13px' } }} />
                    </ListItemButton>
                    <div className='Navbar_CollapseDivider' />
                    <ListItemButton className='myqrtags' sx={{ pl: 4 }} onClick={() => nav('/myqrtags')}>
                      <QrCodeScannerOutlinedIcon style={{ height: '18px', width: '18px', marginRight: '8px' }} />
                      <ListItemText primary="My QR Tags" primaryTypographyProps={{ style: { fontSize: '13px' } }} />
                    </ListItemButton>
                  </List>
                </Collapse>

              </div>

              <div>
                <ListItemButton ref={listRef} onClick={() => toggleSubMenu('security')} style={{ borderBottom: "1px solid lightgray", borderTop: "1px solid lightgray" }}>
                  <ListItemText className='m-0' >
                    <div className='Navbar_CollapseText' >
                      Security
                    </div>
                  </ListItemText>
                  {subMenuStates.security ? <ExpandMore ref={listRef} /> : <ChevronRightIcon ref={listRef} />}
                </ListItemButton>

                <Collapse in={subMenuStates.security} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 4 }} onClick={() => window.location.href = 'https://qrtag.it/terms-and-conditions/'}>
                      <DescriptionOutlinedIcon className='mx-1' style={{ height: '18px', width: '18px', marginRight: '8px' }} />
                      <ListItemText primary="Terms and Conditions" primaryTypographyProps={{ style: { fontSize: '13px' } }} />
                    </ListItemButton>
                    <div className='Navbar_CollapseDivider' />
                    <ListItemButton sx={{ pl: 4 }} onClick={() => window.location.href = 'https://qrtag.it/privacy-policy/'}>
                      <DescriptionOutlinedIcon className='mx-1' style={{ height: '18px', width: '18px', marginRight: '8px' }} />
                      <ListItemText primary="Privacy Policy" primaryTypographyProps={{ style: { fontSize: '13px' } }} />
                    </ListItemButton>
                    <div className='Navbar_CollapseDivider' />
                    <ListItemButton className='feedback' sx={{ pl: 4 }} onClick={() => setFeedback(true)}>
                      <HelpOutlineOutlinedIcon className='mx-1' style={{ height: '18px', width: '18px', marginRight: '8px' }} />
                      <ListItemText primary="Feedback" primaryTypographyProps={{ style: { fontSize: '13px' } }} />
                    </ListItemButton>
                  </List>
                </Collapse>

              </div>

              <div>
                <ListItemButton onClick={() => nav('/story')} style={{ borderBottom: "1px solid lightgray", borderTop: "1px solid lightgray" }}>
                  <ListItemText className='m-0' >
                    <div className='Navbar_CollapseText' >
                      Our Story
                    </div>
                  </ListItemText>
                  {<ChevronRightIcon />}
                </ListItemButton>
              </div>

            </>
          )}

          <div className='Navbar_CollapseDiv_Mob'>
            <ListItemButton onClick={() => nav('/about')}>
              <ListItemText sx={{ margin: '0' }}>
                <div className='Navbar_CollapseText'>
                  <p className='mb-0'>About Us</p>
                </div>
              </ListItemText>
              {<ChevronRightIcon />}
            </ListItemButton>
            <div className='Navbar_CollapseDivider' />
            {/* <ListItemButton>
              <ListItemText sx={{ margin: '0' }}>
                <Link to='/store' className='Navbar_CollapseText'>
                  <p className='mb-0'>Store</p>
                </Link>
              </ListItemText>
            </ListItemButton>
            <div className='Navbar_CollapseDivider' /> */}
            {loggedIn &&
              <ListItemButton className='mobi-mystuff' onClick={() => nav('/')}>
                <ListItemText sx={{ margin: '0' }} >
                  <div className='Navbar_CollapseText'>
                    <p className='mb-0 '>My Stuff</p>
                  </div>
                </ListItemText>
                {<ChevronRightIcon />}
              </ListItemButton>}
            <div className='Navbar_CollapseDivider' />
            {/* <ListItemButton onClick={() => window.open(device_type === "Android" ? "https://play.google.com/store/apps/details?id=com.withered_feather_36062" : "https://apps.apple.com/us/app/qrtag-it/id6444082603")}>
              <ListItemText sx={{ margin: '0' }}>
                <div
                  className='Navbar_CollapseText'>
                  Download the App
                </div>
              </ListItemText>
              {<ChevronRightIcon />}
            </ListItemButton>
            <div className='Navbar_CollapseDivider' /> */}
            {!loggedIn && (
              <ListItemButton onClick={() => (window.location = '/')}>
                <ListItemText>
                  <div className='Navbar_SignOutDiv'>
                    <div className='Navbar_CollapseText'> SignIn</div>
                    <div>
                      {loading && <PulseLoader size={10} color='#0A3F74' />}
                    </div>
                  </div>
                </ListItemText>
              </ListItemButton>
            )}
          </div>
        </List>
      </Collapse>

      <ConfirmationModel
        open={cancel}
        setOpen={setCancel}
        onConfirm={() => {
          if (loading) {
            return;
          }
          setLoading((s) => !s);
          localStorage.setItem('isLoggedIn', 'false');
          localStorage.clear();
          sessionStorage.clear();
          setCancel(false);
          nav('/');
          setTimeout(() => {
            window.location.reload();
          }, 300)
        }}
      ></ConfirmationModel>

      {deleteAccount && (
        <ConfirmationDeleteModel
          open={deleteAccount}
          setOpen={setDeleteAccount}
        />
      )}
      {feedback && (
        <FeedbackModal
          open={feedback}
          setOpen={setFeedback}
        />
      )}
      {applycoupon && (
        <ApplyCouponModal
          open={applycoupon}
          setOpen={setApplyCoupon}
          navigation={true}
        />
      )}
      {cancel && (
        <div className='fullScreenLoader'>
          <PulseLoader color={'#0057ff'} loading={loading} size={10} />
        </div>
      )}
      {(startGuide) && <>
        <AppGuide
          setStartGuide={setStartGuide}
          setOpen={setOpen}
          setOpenMenu={setOpenMenu}
          toggleSubMenu={toggleSubMenu}
          overlayRef={overlayRef}
          floaterRef={floaterRef}
        />
      </>}
      
      {(questionModal) && <>
        <QuestionModal
          open={questionModal}
          setOpen={setQuestionModal}
        />
      </>}
    </>
  );
}

export default Navbar;
