import React, { useEffect, useRef, useState } from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import './css/App.css';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Axios from '../config/axios';
import FilePicker from './FilePicker';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify';
import { PulseLoader } from 'react-spinners';
import { useForm, Controller } from 'react-hook-form';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -38%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: "10px",
    width: "40%",
    p: 4,
    overflow: 'auto'
};


const EditModal = ({ handleClose, open, singleData, handleSingleData }) => {

    const { handleSubmit, control, formState: { errors }, getValues, setValue } = useForm({
        defaultValues: {
            itemName: '',
            manufacturer: '',
            serialNo: '',
            estimated: '',
            description: '',
            photos: ['', '', ''],
        },
    })

    const [photosDeleted, setPhotosDeleted] = useState([]);
    const [loading, setLoading] = React.useState(false);

    const uploadInputRefs = useRef([React.createRef(), React.createRef(), React.createRef()]);

    useEffect(() => {
        if (singleData && open) {
            setValue('itemName', singleData?.name)
            setValue('manufacturer', singleData?.manufacturer)
            setValue('serialNo', singleData?.serial_no)
            setValue('estimated', singleData?.estimated_value)
            setValue('description', singleData?.description)
            setValue('photos', singleData?.photos?.map((item) => item.photo) || ['', '', ''])
            setValue('images', singleData?.photos || [])
        }
    }, [singleData, open, setValue])


    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === 'estimated') {
            if (value === '' || /^\d*\.?\d{0,2}$/.test(value)) {
                setValue(name, value);
            }
        } else {
            setValue(name, value);
        }
    };


    const handleFile = (e) => {
        e.stopPropagation()
        const files = e.target?.files
        const images = []

        for (let i = 0; i < files.length; i++) {
            const reader = new FileReader()
            reader.readAsDataURL(files[i]) // Convert image to Base64
            reader.onloadend = () => {
                images.push(reader.result) // Push the Base64 string
                setValue('photos', [...getValues('photos'), ...images], { shouldValidate: true })
            }
        }
    }

    const handleClick = (index) => {
        uploadInputRefs.current[index].click();
    };

    const handleRemoveImage = (index) => {
        const removedImage = getValues('photos')[index]

        const imageToDelete = singleData?.photos[index]

        if (imageToDelete && imageToDelete.id) {
            setPhotosDeleted((prev) => [...prev, imageToDelete.id])
        }

        const updatedPhotos = getValues('photos').filter((_, i) => i !== index)
        setValue('photos', updatedPhotos, { shouldValidate: true })
    }


    const handleSaveEdit = () => {

        if (loading) {
            return;
        }

        const estimatedValue = parseFloat(getValues('estimated')).toFixed(2);

        const params = {
            name: getValues('itemName'),
            manufacturer: getValues('manufacturer'),
            serial_no: getValues('serialNo'),
            estimated_value: estimatedValue,
            description: getValues('description'),
            photos: [{ photo: getValues('photos') }],
            deleted_photos: photosDeleted,
        };

        const images = [];
        const photos = getValues('photos')
        if (photos && photos.length > 0) {
            for (let i = 0; i < photos.length; i++) {
                if (!photos[i].includes('/media/uploads/')) {
                    images.push({ photo: photos[i], is_cover: (i === 0) ? true : false })
                }
            }
        }

        params.photos = images;

        setLoading(true)

        Axios.patch(`/looser/items/${singleData?.id}/`, params)
            .then((res) => {
                setLoading(false)
                if (res) {
                    toast.success('Item details updated successfully!');
                    handleClose();
                    handleSingleData()
                }
            })
            .catch((err) => {
                toast.error('Update Failed!');
                setLoading(false)
            });

    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableEscapeKeyDown
            style={{ overflowY: 'auto' }}
        >
            <Box sx={style}>
                <div style={{ position: 'relative', width: '100%' }}>
                    <div
                        style={{
                            position: 'absolute',
                            right: '-5%',
                            marginTop: '-5%',
                            border: '2px solid #000',
                            borderRadius: '50%',
                            padding: '3px',
                            backgroundColor: '#fff',
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </div>

                </div>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Update Item
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>

                    <form onSubmit={handleSubmit(handleSaveEdit)}>
                        <div className='all_fields'>
                            {/* <p>Name</p> */}
                            <label for="name" style={{ display: "inline-flex", fontWeight: "bold" }}>
                                Name <span className='text-danger'>*</span>
                            </label>

                            <Controller
                                name="itemName"
                                control={control}
                                rules={{ required: 'Name is required' }}
                                render={({ field }) => (
                                    <input
                                        {...field}
                                        className='input_field'
                                        type="text"
                                    />
                                )}
                            />
                            {errors.itemName && <p className="error-validation">{errors.itemName.message}</p>}

                            {/* <p>Manufacturer</p> */}
                            <label for="manufacturer" style={{ display: "inline-flex", fontWeight: "bold" }}>
                                Manufacturer <span className='text-danger'>*</span>
                            </label>

                            <Controller
                                name="manufacturer"
                                control={control}
                                rules={{ required: 'Manufacturer is required' }}
                                render={({ field }) => (
                                    <input
                                        {...field}
                                        className='input_field'
                                        type="text"
                                    />
                                )}
                            />
                            {errors.manufacturer && <p className="error-validation">{errors.manufacturer.message}</p>}
                            {/* <p>Serial No</p> */}
                            <label for="serial" style={{ display: "inline-flex", fontWeight: "bold" }}>
                                Serial No<span className='text-danger'>*</span>
                            </label>

                            <Controller
                                name="serialNo"
                                control={control}
                                rules={{ required: 'Serial No is required' }}
                                render={({ field }) => (
                                    <input
                                        {...field}
                                        className='input_field'
                                        type="text"
                                    />
                                )}
                            />
                            {errors.serialNo && <p className="error-validation">{errors.serialNo.message}</p>}

                            <label for="estimated" style={{ display: "inline-flex", fontWeight: "bold" }}>
                                Estimated Value<span className='text-danger'>*</span>
                            </label>

                            <Controller
                                name="estimated"
                                control={control}
                                rules={{
                                    required: 'Estimated Value is required ',

                                }}
                                render={({ field }) => (
                                    <input
                                        {...field}
                                        className='input_field'
                                        type="text"
                                        onInput={(e) => {
                                            e.target.value = e.target.value.replace(/[^0-9.]/g, '')
                                                .replace(/(\..*?)\..*/g, '$1')
                                                .replace(/^(\d+)(\.\d{0,2})?.*/, '$1$2');
                                        }}
                                    />
                                )}
                            />
                            {errors.estimated && <p className="error-validation">{errors.estimated.message}</p>}

                            {/* <p>Description</p> */}
                            <label for="description" style={{ display: "inline-flex", fontWeight: "bold" }}>
                                Description<span className='text-danger'>*</span>
                            </label>

                            <Controller
                                name="description"
                                control={control}
                                rules={{ required: 'Description is required' }}
                                render={({ field }) => (
                                    <textarea
                                        {...field}
                                        className='text_field'
                                        cols="50"
                                        rows="5"
                                    />
                                )}
                            />
                            {errors.description && <p className="error-validation mt-3">{errors.description.message}</p>}

                            {/* <input
                            ref={uploadInputRef} accept="image/*" type="file" id='photo_file' style={{ display: 'none' }} />
                        <label htmlFor="photo_file" className='photo_upload'>
                            <AddOutlinedIcon sx={{ color: "rgb(10, 63, 116)" }} />
                        </label> */}
                            {/* <FilePicker onChange={handleFile} fileName={data.photos} /> */}


                            <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>

                                <Controller
                                    name="photos"
                                    control={control}
                                    rules={{
                                        validate: {
                                            maxFiles: (value) => value?.length === 3 || 'You must upload 3 photos'
                                        },
                                    }}
                                    render={({ field }) => (
                                        <>
                                            {field.value.map((photo, index) => (
                                                <div
                                                    key={index}
                                                    style={{
                                                        width: 70,
                                                        height: 70,
                                                        borderRadius: '10%',
                                                        backgroundColor: '#f5f5f5',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        cursor: 'pointer',
                                                        position: 'relative',
                                                        marginRight: '10px',
                                                        marginTop: '15px',
                                                        border: '1px solid rgb(223, 223, 223)',
                                                    }}
                                                >
                                                    <img
                                                        src={photo}
                                                        alt={`Selected ${index}`}
                                                        style={{ width: '100%', height: '100%', borderRadius: '8px' }}
                                                    />
                                                    <div
                                                        style={{
                                                            position: 'absolute',
                                                            top: '-8px',
                                                            right: '-8px',
                                                            border: '2px solid #ff0000',
                                                            borderRadius: '50%',
                                                            padding: '3px',
                                                            backgroundColor: '#ff0000',
                                                            cursor: 'pointer',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                        }}
                                                        onClick={() => handleRemoveImage(index)}
                                                    >
                                                        <CloseIcon style={{ width: '13px', height: '13px', color: 'white' }} />
                                                    </div>
                                                </div>
                                            ))}

                                            {Array.from({ length: 3 - field.value.length }).map((_, index) => (
                                                <div
                                                    key={`upload-${index}`}
                                                    style={{
                                                        width: 70,
                                                        height: 70,
                                                        borderRadius: '10%',
                                                        backgroundColor: '#f5f5f5',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        cursor: 'pointer',
                                                        position: 'relative',
                                                        marginRight: '10px',
                                                        marginTop: '15px',
                                                        border: '1px solid rgb(223, 223, 223)',
                                                    }}
                                                    onClick={() => handleClick(index)}
                                                >
                                                    <AddOutlinedIcon style={{ width: '50%', height: '50%' }} />

                                                    <input
                                                        type="file"
                                                        accept="image/*"
                                                        style={{ display: 'none' }}
                                                        ref={(el) => (uploadInputRefs.current[index] = el)}
                                                        onChange={(e) => handleFile(e, field.value.length + index)}
                                                    />
                                                </div>
                                            ))}
                                        </>
                                    )}
                                />

                            </div>
                            <div className="mt-3">
                                {errors.photos && (
                                    <p className="error-validation" style={{ textAlign: 'left' }}>
                                        {errors.photos.message}
                                    </p>
                                )}
                            </div>

                            <button
                                style={{
                                    backgroundColor: "#0a3f74"
                                }}
                                className='found_btn'>
                                {!loading ? (
                                    <div>Save</div>
                                ) : (
                                    <PulseLoader size={15} color='#ffffff' />
                                )}
                            </button>

                        </div>
                    </form>
                </Typography>
            </Box>
        </Modal>
    )
}

export default EditModal