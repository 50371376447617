/* eslint-disable jsx-a11y/alt-text */
import '../css/App.css';
import QR_BG from '../../images/login-bg.svg';
import HidePassword from '../../images/hide-password.svg';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { Auth } from 'aws-amplify';
import { useState } from 'react';
import { PulseLoader } from 'react-spinners';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import PhoneInput from 'react-phone-number-input';
import { parsePhoneNumber, validatePhoneNumberLength } from 'libphonenumber-js';
import 'react-phone-number-input/style.css';

import { AuthSignup } from '../../services/auth';
import SocialLogin from '../common/SocialLogin';
import { FormControl } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';

function SignUp() {

  const nav = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  let [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false)

  const { handleSubmit, control, formState: { errors }, getValues } = useForm({
    defaultValues: {
      name: '',
      email: '',
      phone: '',
      password: ''
    },
  })

  async function signUp() {

    if (loading) {
      return;
    }
    
    try {

      const params = {
        name: getValues('name'),
        email: getValues('email'),
        password: getValues('password'),
        phone_number: getValues('phone').replace(/\+/g, '')
      }

      try {
        const phone = (getValues('phone').startsWith('+') ? getValues('phone') : `+${getValues('phone')}`)

        if (validatePhoneNumberLength(phone) === 'TOO_SHORT') {
          toast.error('Please enter a valid phone number');
          return false
        }

        const parsedNumber = parsePhoneNumber(phone);
        if (parsedNumber) {
          params.phone_number = parsedNumber?.nationalNumber
          params.country_code = parsedNumber?.country || 'US'

          if (parsedNumber?.nationalNumber === "") {
            toast.error('Please enter a valid phone number');
            return false
          }
        }
      } catch (e) {
        console.error(e);
      }

      setLoading((s) => !s);
      
      AuthSignup(params, (response) => {
        setLoading((s) => !s);
        if (response && response.success) {
          nav('/verificationcode', { state: { username: getValues('email') } });
        } else {
          const errorData = response.error?.data;
          if (errorData && errorData.phone_number && errorData.phone_number.length > 0) {
            toast.error(errorData.phone_number[0]);
          } else if (errorData && errorData.email) {
            toast.error(errorData.email);
          }

        }
      });

    } catch (error) {
      setLoading((s) => !s);
      if (error.message === 'Invalid phone number format.') {
        toast.error('Invalid phone number format.');
      } else if (error.message === 'An account with the given email already exists') {
        toast.error('An account with the given email already exists');
      } else {
        toast.error(error.message);
      }
      console.log('error signing up', error);
    }

  }
  return (
    <>
      {/* <div style={{ position: 'absolute', bottom: '2%', right: '2%' }}>
        <Link to='/about' style={{ fontWeight: '600', textDecoration: 'none' }}>
          About Us
        </Link>
      </div> */}
      <div className='LogInContainer'>
        <div className='LogIn_ImageDiv'>
          <img className='LogIn_Image' src={QR_BG} />
        </div>
        <div className='LogIn_RightDiv py-lg-5 py-md-4 py-0'>
          <form className='LogIn_FormDiv' onSubmit={handleSubmit(signUp)}>
            <div className='LogIn_HeadingDiv'>
              <h2>Hello,</h2>
              <p>let’s help find your lost item</p>
            </div>
            <div className='LogIn_InputDiv'>
              <h3 className='pb-4'>Sign Up</h3>

              <FormControl fullWidth className='mb-1'>
                <label htmlFor="Name" style={{ display: "inline-flex" }}>
                  Full Name <span className='text-danger'>*</span>
                </label>
                <Controller
                  name='name'
                  control={control}

                  rules={{
                    required: 'Full Name is required',
                  }}
                  render={({ field }) => (
                    <input
                      {...field}
                      className='LogIn_InputEmail'
                      type='text'
                      placeholder='Enter Full Name'
                      id="Name"
                    />
                  )}
                />
                {errors.name && (<p className='error-validation'>{errors.name.message}</p>)}
              </FormControl>


              <FormControl fullWidth className='mb-1'>
                <label htmlFor="Email" style={{ display: "inline-flex" }}>
                  Email <span className='text-danger'>*</span>
                </label>
                <Controller
                  name='email'
                  control={control}

                  rules={{
                    required: 'Email is required',
                    pattern: {
                      value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                      message: 'Invalid email address',
                    },
                  }}
                  render={({ field }) => (
                    <input
                      {...field}
                      className='LogIn_InputEmail'
                      type='email'
                      placeholder='Enter Email'
                      id="Email"
                    />
                  )}
                />
                {errors.email && (<p className='error-validation'>{errors.email.message}</p>)}
              </FormControl>

              <FormControl fullWidth className='mb-1'>
                <label htmlFor="Phone" style={{ display: "inline-flex" }}>
                  Phone Number <span className='text-danger'>*</span>
                </label>
                <Controller
                  name='phone'
                  control={control}

                  rules={{
                    required: 'Phone number is required',
                  }}
                  render={({ field }) => (
                    <PhoneInput
                      {...field}
                      international
                      className='LogIn_InputEmail'
                      placeholder='Enter Phone Number'
                      defaultCountry='US'
                    />
                  )}
                />
                {errors.phone && (<p className='error-validation'>{errors.phone.message}</p>)}
              </FormControl>

              <FormControl fullWidth className='mb-1'>
                <label htmlFor="Password" style={{ display: "inline-flex" }}>
                  Password <span className='text-danger'>*</span>
                </label>
                <Controller
                  name='password'
                  control={control}

                  rules={{
                    required: 'Password is required',
                    minLength: {
                      value: 8,
                      message: 'Password must be at least 8 characters',
                    },
                  }}
                  render={({ field }) => (
                    <div className='LogIn_InputPasswordDiv'>
                      <input
                        {...field}
                        className='LogIn_InputPassword'
                        type={!showPassword ? 'password' : 'text'}
                        placeholder='Enter Password'
                        id="Password"
                      />
                      <div>
                        {showPassword ? (
                          <VisibilityOutlinedIcon
                            style={{ color: '#818181', cursor: 'pointer' }}
                            onClick={() => setShowPassword(!showPassword)}
                          />
                        ) : (
                          <VisibilityOffOutlinedIcon
                            style={{ color: '#818181', cursor: 'pointer' }}
                            onClick={() => setShowPassword(!showPassword)}
                          />
                        )}
                      </div>
                    </div>
                  )}
                />
                {errors.password && (<p className='error-validation'>{errors.password.message}</p>)}

              </FormControl>
              {/* <input
                className='LogIn_InputEmail'
                value={formState.coupon}
                onChange={(e) => {
                  setFormState((s) => ({ ...s, coupon: e.target.value }));
                }}
                type='text'
                placeholder='Coupon Code'
              /> */}
            </div>
            {/* <div className='LogIn_ForgotPasswordDiv'>
              <p>Forgot password?</p>
            </div> */}

            {/* <div className='LogIn_SignUpDiv mt-2' style={{ display: 'flex', alignItems: 'start', justifyContent: 'start' }}>
              <input className="form-check-input" style={{ width: '2em' }} name="development" value={checked} onChange={(e) => setChecked(e.target.checked)} type="checkbox"></input>
              <p className='ml-1 text-align-left-important'>
                I have read, understood, and hereby accept the&nbsp;
                <a href='https://qrtag.it/terms-and-conditions/' target='_blank' style={{ color: '#0A3F74', fontWeight: '600' }}>
                  Terms and Conditions
                </a> and&nbsp;
                <a href='https://qrtag.it/privacy-policy/' target='_blank' style={{ color: '#0A3F74', fontWeight: '600' }}>
                  Privacy Policy
                </a>
              </p>
            </div> */}

            <div className='LogIn_BtnDiv'  >
              <button className='LogIn_Btn'>
                {!loading ? <div> Sign Up</div> : <PulseLoader size={15} color='#ffffff' />}
              </button>
              {/* <ClipLoader
                color={color}
                loading={loading}
                cssOverride={override}
                size={60}
                aria-label='Loading Spinner'
                data-testid='loader'
              /> */}
            </div>
            <div className='LogIn_SignUpDiv'>
              <p>
                Already have an account?{' '}
                <Link to='/' style={{ color: '#0A3F74', fontWeight: '700' }}>
                  Sign In
                </Link>
              </p>
            </div>
            <div className='LogIn_DividerDiv'>
              <div className='LogIn_Divider' />
              <p>OR</p>
              <div className='LogIn_Divider' />
            </div>
            <div className='LogIn_SignInOptions'>
              <p>Sign up with</p>
            </div>
            <SocialLogin />
          </form>
        </div >
      </div >
    </>
  );
}

export default SignUp;
